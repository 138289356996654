const initSegmentedControl = () => {
  const wrapper = document.querySelector("[data-segmented-control]");
  if (wrapper) {
    const wrapperWidth =
      parseFloat(getComputedStyle(wrapper).width) -
      parseFloat(getComputedStyle(wrapper).paddingLeft) -
      parseFloat(getComputedStyle(wrapper).paddingRight);

    const container = wrapper.querySelector(".segmented-control");
    container.childNodes.forEach(button => {
      button.style.maxWidth = "";
      button.style.flexBasis = "";
    });
    const buttonsCount = container.childElementCount;
    const buttonWidth = parseFloat(
      getComputedStyle(container.childNodes[0]).width
    );

    const buttonsShown = Math.floor(Math.ceil(wrapperWidth) / buttonWidth);
    let step = 1;
    const maxStep = Math.ceil(buttonsCount / buttonsShown);

    if (buttonsShown > 5) {
      container.childNodes.forEach(button => {
        button.style.maxWidth = "none";
        button.style.flexBasis = 100 / buttonsShown + "%";
      });
    }
    wrapper.classList.remove("segmented-wrapper--has-back");
    wrapper.classList.remove("segmented-wrapper--has-forward");

    let position = 0;
    if (maxStep > 1) {
      container.style.transform = `translateX(${position}%)`;
      wrapper.classList.remove("segmented-wrapper--has-back");
      wrapper.classList.add("segmented-wrapper--has-forward");

      // Check if the buttons already exist
      let buttonForward = wrapper.querySelector(".segmented-control__arrow--forward");
      let buttonBack = wrapper.querySelector(".segmented-control__arrow--back");

      if (!buttonForward) {
        buttonForward = document.createElement("button");
        buttonForward.setAttribute('aria-label', 'Forward');
        buttonForward.classList.add(
          "segmented-control__arrow",
          "segmented-control__arrow--forward"
        );
        wrapper.appendChild(buttonForward);
      }

      if (!buttonBack) {
        buttonBack = document.createElement("button");
        buttonBack.setAttribute('aria-label', 'Back');
        buttonBack.classList.add(
          "segmented-control__arrow",
          "segmented-control__arrow--back"
        );
        wrapper.appendChild(buttonBack);
      }

      buttonForward.addEventListener("click", function() {
        position = position - 100;
        container.style.transform = `translateX(${position}%)`;
        wrapper.classList.add("segmented-wrapper--has-back");
        step++;
        if (step >= maxStep) {
          wrapper.classList.remove("segmented-wrapper--has-forward");
        }
      });
      buttonBack.addEventListener("click", function() {
        position = position + 100;
        container.style.transform = `translateX(${position}%)`;
        step--;
        wrapper.classList.add("segmented-wrapper--has-forward");
        if (step === 1) {
          wrapper.classList.remove("segmented-wrapper--has-back");
        }
      });
    }
  }
};

export default initSegmentedControl;
